@use '../media';

h1, h2, h3, h4, h5, h6 {
    text-wrap: balance;
    font-family: var(--font-heading);
    font-weight: 400;
    orphans: 2;
}

h1 {
    font-size: 2.65em;
    @include media.sm {
        font-size: 3.2em;
    }
}

h2 {
    margin-block: 2rem;
    font-size: 2.05em;
    @include media.sm {
        font-size: 2.3em;
    }
}

h3 {
    margin-block: 1rem;
    font-size: 1.2em;
}

p {
    margin-block: .25rem;
    color: var(--soft-body);
}
