// Fonts en local
$url-dossier-font: "../fonts" !default;

$family-base: "Tahoma", system-ui, -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
$family-heading: "Luciole";

$size-root: 10px !default;

$size-base: 16px !default;
$line-height-base: 1.3 !default;

:root {
    --font-base: #{$family-base};
    --font-heading: #{$family-heading};
}

@font-face {
    font-family: Tahoma;
    src:
        url("../fonts/Tahoma/Tahoma-Regular.woff") format("woff"),
        url("../fonts/Tahoma/Tahoma-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Tahoma;
    src:
        url("../fonts/Tahoma/Tahoma-Bold.woff") format("woff"),
        url("../fonts/Tahoma/Tahoma-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Luciole;
    src:
        url("../fonts/Luciole/Luciole-Regular.woff") format("woff"),
        url("../fonts/Luciole/Luciole-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Luciole;
    src:
        url("../fonts/Luciole/Luciole-Bold.woff") format("woff"),
        url("../fonts/Luciole/Luciole-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}
