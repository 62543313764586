@use '../media';

#temoignages {
    position: relative;
    @include media.sm {
        padding-block-start: var(--section-p-lg);
    }

    blockquote {
        margin: 1rem;
        font-family: var(--font-heading);
        font-size: 2em;
    }

    figcaption {
        color: var(--gray-500);
    }

    #partenaires {
        width: fit-content;
        margin-inline: auto;
        padding-block: var(--section-p-lg) var(--section-p);

        img {
            max-width: 15rem;
            max-height: 12rem;
        }
    }

    // Ring
    .layer-ring {
        position: absolute;
        top: -20%;
        right: -20vw;
        height: 110%;
        aspect-ratio: 1 / 1;
        max-width: 50%;
        pointer-events: none;
        z-index: -1;
    }
    @include media.xs {
        figure {
            position: relative;

            .layer-ring {
                height: 250%;
                max-width: initial;
                top: 50%;
                transform: translateY(-50%);
                right: initial;
                left: 66vw;
            }
        }
    }
}
