.btn {
    position: relative;
    padding-inline: 4rem;
    padding-block: 1.7rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: .75ch;
    font-family: var(--font-heading);
    font-size: .85em;
    line-height: 1;
    text-decoration: none;
    background: none;
    border: .2rem solid transparent;
    border-radius: 100vmax;
    text-transform: uppercase;
    cursor: pointer;
    transition-property: box-shadow, color, background-color, border-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;

    > * {
        display: inline-block;
        vertical-align: middle;
        text-underline-offset: .4rem;
    }

    .icon {
        font-size: 1.5em;
    }

    &:not(:disabled) {
        &:not(.btn-icon) {
            &:hover,
            &:focus-visible {
                span:not(.icon) {
                    text-decoration: underline;
                }
            }
        }
    }

    &-primary {
        color: var(--body);
        background-color: var(--primary);
        border-color: var(--primary);

        &:hover {
            background-color: var(--primary-light);
            border-color: var(--primary-light);
        }

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 4px var(--primary-shadow);
            background-color: var(--primary-light);
            border-color: var(--primary-light);
        }
    }

    &-secondary {
        color: var(--body);
        border-color: var(--primary);

        &:hover {
            background-color: var(--primary-light);
            border-color: var(--primary-light);
        }

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 4px var(--primary-shadow);
            background-color: var(--primary-light);
            border-color: var(--primary-light);
        }
    }

    &-block {
        display: flex;
        width: 100%;
    }
}
