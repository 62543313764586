@use '../media';

$top-z: 15;
$mid-z: 10;
$bottom-z: 5;

.hero {
    position: relative;
    isolation: isolate;
    min-height: 65rem;
    height: 85dvh;
    height: 85vh;
    display: grid;
    place-items: center;
    background-image: url("../../images/webp/hero-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;
    z-index: 1;
    @include media.xs {
        padding-top: 10rem;
    }

    h1 {
        position: relative;
        padding-block: 2rem;
        padding-inline: 3rem;
        border-left: .4rem solid var(--primary);
        background-color: var(--white);
        z-index: $mid-z;
        @include media.sm {
            > strong:last-of-type {
                position: relative;

                &::after {
                    display: block;
                    content: url("../../images/raw/hero-signature.svg");
                    position: absolute;
                    bottom: calc(-1em - 1.5rem);
                    right: 0;
                }
            }
        }
    }

    // Ring
    .layer-ring {
        position: absolute;
        z-index: $bottom-z;
        aspect-ratio: 1 / 1;
        pointer-events: none;
        user-select: none;
        top: -70%;
        left: calc(15dvw + 25%);
        left: calc(15vw + 25%);
        height: calc(110dvh + 70%);
        height: calc(110vh + 70%);
    }
    @include media.xs {
        .layer-ring {
            top: -20%;
        }
    }

    // People
    &::after {
        display: block;
        content: url("../../images/raw/hero-layer-people-xs.svg");
        position: absolute;
        bottom: -1rem;
        z-index: $top-z;
        pointer-events: none;
        max-width: 100%;
        min-width: 40rem;
        width: calc(90% - 8rem);
    }
    @include media.sm {
        &::after {
            content: url("../../images/raw/hero-layer-people.svg");
            width: 90rem;
        }
    }
}
