@use '../media';
@use '../colors';
@use '../core/grid';

.site-header {
    margin-inline: auto;
    padding-block: 2rem;
    padding-inline: 4rem;
    position: absolute;
    top: 0;
    inset-inline: 0;
    z-index: 10;
    background-color: var(--white);
    @include media.sm {
        .flex {
            justify-content: space-between;
        }
    }
    @include media.xs {
        .flex {
            justify-content: center;

            &.f {
                flex-flow: column;
            }
        }
    }

    img {
        max-height: 6rem;
        max-width: 18rem;
        width: auto;
    }
}


