@use './colors';

.form-group {
    label,
    .form-label {
        display: inline-block;
        margin-bottom: .5em;
        font-weight: 700;
    }

    textarea {
        max-width: 100%;
        min-width: 100%;
        min-height: 12.5vh;
        max-height: 33vh;
        font-size: .9em;
    }

    select,
    .form-text {
        display: block;
        width: 100%;
        height: 100%;
        padding: 1rem;
        background: var(--white);
        border: .1rem solid var(--gray-400);
        border-radius: 1.1rem;

        &:not(.disabled),
        &:not(:disabled) {
            transition: box-shadow 0.3s ease;

            &:focus {
                outline: none;
                box-shadow: 0 0 0 .3rem rgba(colors.$gray-500, 30%);
            }
        }
    }

    .form-checkbox {
        position: relative;
        margin-bottom: 5px;

        label {
            font-weight: 400;
            font-size: .9em;
            margin-bottom: 0;
        }

        input[type=checkbox] {
            margin-right: .5rem;
            position: absolute;
            left: -100vw;

            & ~ label {
                padding-left: 24px;

                &:before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    display: inline-block;
                    content: '';
                    border: .1rem solid var(--gray-400);
                    border-radius: .2rem;
                    background: var(--white);
                    width: 1.5rem;
                    height: 1.5rem;
                    line-height: 1.5rem;
                    text-align: center;
                    transition: box-shadow 0.3s ease;
                }
            }

            &:not(:disabled) {
                &:hover {
                    & ~ label:before {
                        border-color: var(--primary);
                    }
                }
                &:focus {
                    & ~ label:before {
                        box-shadow: 0 0 0 4px var(--primary-shadow);
                    }
                }
            }

            &:checked {
                & ~ label:before {
                    content: '✓';
                    font-size: 10px;
                    font-weight: bold;
                    color: var(--white);
                    background: var(--primary);
                    border-color: var(--primary);
                }

                &:disabled {
                    & ~ label:before {
                        background: var(--gray-500);
                        border-color: var(--gray-500);
                    }
                }
            }
        }
    }

    .form-radio {
        position: relative;

        input[type=radio] {
            margin-right: .5rem;
            position: absolute;
            left: -100vw;

            & ~ label {
                padding-left: 2.5rem;
                margin-bottom: 0;
                font-weight: 400;

                &:before,
                &:after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    display: inline-block;
                    content: '';
                    background: var(--white);
                }

                &:before {
                    left: 0;
                    border: 1px solid var(--gray-400);
                    border-radius: 1.5rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    transition: box-shadow 0.3s ease;
                }

                &:after {
                    left: .5rem;
                    width: .5rem;
                    height: .5rem;
                    border-radius: .5rem;
                }
            }

            &:not(:disabled) {
                &:hover {
                    & ~ label:before {
                        border-color: var(--primary);
                    }
                }
                &:focus {
                    & ~ label:before {
                        box-shadow: 0 0 0 .4rem var(--primary-shadow);
                    }
                }
            }

            &:checked {
                & ~ label:before {
                    font-size: .9em;
                    font-weight: bold;
                    color: var(--white);
                    background: var(--primary);
                    border-color: var(--primary);
                }

                &:disabled {
                    & ~ label:before {
                        background: var(--gray-400);
                        border-color: var(--gray-400);
                    }
                }
            }
        }
    }

    .form-geoloc {
        position: relative;
        isolation: isolate;

        input[type="text"] {
            padding-right: 5rem;
        }

        .geoloc {
            position: absolute;
            height: 100%;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            font-size: 1.4em;
            border: none;
            background: none;
            cursor: pointer;
            z-index: 2;
            line-height: 1;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: var(--primary);
            }
        }
    }

    &.disabled {
        label {
            opacity: 50%;
        }

        select,
        .form-text {
            background: var(--gray-100);
            border-color: var(--gray-100);
        }
    }

    &:not(.disabled) {
        .form-checkbox {
            cursor: pointer;

            label,
            input[type=checkbox] {
                cursor: inherit;
            }
        }
        .form-radio {
            label {
                cursor: pointer;
            }
        }
    }

    .form-tooltip {
        margin-top: .5em;
        color: var(--gray-400);
    }
}
