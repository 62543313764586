@use "libs/normalize";

@use './core/grid';
@use './core/visibility';

@use "fonts";
@use "utilities";
@use "colors";

html {
    box-sizing: border-box;
    font-size: fonts.$size-root;
    -ms-overflow-style: scrollbar;
    height: calc(100vh - 32px);
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
}

html *,
html *::before,
html *::after {
    box-sizing: border-box;
}

// WP Bar
@media screen and (width < 782px) {
    html {
        height: calc(100vh - 46px);
    }
}

@media screen and (width < 600px) {
    html #wpadminbar {
        position: fixed !important;
    }
}

body {
    font-family: fonts.$family-base;
    font-size: fonts.$size-base;
    color: var(--body);
    background-color: var(--bg-body);
    min-height: 100%;
    position: relative;
    overflow-x: hidden;

    p {
        line-height: fonts.$line-height-base;
    }
}

:root {
    --section-p: 5rem;
    --section-p-lg: 10rem;
}

