@use '../media';

#a-propos {
    padding-block: var(--section-p-lg);
    @include media.xs {
        padding-top: 2rem;
    }

    figure {
        border-radius: 2rem;
        overflow: hidden;
    }

    article {
        > * + * {
            display: inline-block;
            margin-top: 1em;
        }
    }
}
