$white: #FFF;
$black: #1D1D1B;

$gray-100: #E9E8E9;
$gray-500: #616160;

$orange-100: #FCF3EB;
$orange-400: #ff9124;
$orange-500: #ED7F15;
$orange-600: #BB630E;

$orange-shadow: #ED7F1533;

$theme: (
    "white": $white,
    "black":  $black,

    "gray-100": $gray-100,
    "gray-500": $gray-500,

    "orange-100": $orange-100,
    "orange-400": $orange-400,
    "orange-500": $orange-500,
    "orange-600": $orange-600,

    "body": $black,
    "soft-body": $gray-500,
    "bg-body": $white,

    "primary-shadow": $orange-shadow,
    "primary-light": $orange-400,
    "primary": $orange-500,
    "primary-dark": $orange-600,
);

/**
* Exposes colors to the DOM
*/
:root {
    @each $name, $color in $theme {
        --#{$name}: #{$color};
    }
}
