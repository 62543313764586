@use './breakpoints' as bp;

@mixin lg {
    @media screen and (width >= bp.$desktop) {
        @content;
    }
}

@mixin md {
    @media screen and (width >= bp.$desktop) {
        @content;
    }
}

@mixin sm {
    @media screen and (width >= bp.$mobile) {
        @content;
    }
}

@mixin xs {
    @media screen and (width < bp.$mobile) {
        @content;
    }
}
