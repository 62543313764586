details {
    summary {
        position: relative;
        margin-bottom: 1rem;
        padding-bottom: .5rem;
        padding-right: 3rem;
        list-style: none;
        border: none;
        border-bottom: .1rem solid var(--gray-500);
        cursor: pointer;
        transition: color 0.16s ease-in-out;

        &:hover,
        &:focus-visible {
            color: var(--primary);
        }

        &::-webkit-details-marker  {
            display: none;
        }

        &::after {
            display: block;
            content: url("../../images/raw/icon-plus.svg");
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
            right: 0;
        }
    }

    &[open] {
        summary {
            &::after {
                content: url("../../images/raw/icon-minus.svg");
            }
        }
    }
}
