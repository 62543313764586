@use '../media';

#faq {
    position: relative;
    color: var(--bg-body);
    padding-block: var(--section-p);
    background-color: var(--body);
    overflow: hidden;
    @include media.sm {
        padding-block: var(--section-p-lg);
    }

    p {
        color: var(--bg-body);
        opacity: .6;
    }

    header {
        $arrow-w: 13.4rem;

        position: relative;
        @include media.sm {
            margin-top: 3.5rem;
            margin-right: calc($arrow-w / 2);

            &::after {
                top: 50%;
                right: calc( calc($arrow-w * -1) + calc($arrow-w / 2));
                transform: translateY(-50%);
                height: 3rem;
                width: $arrow-w;
            }
        }
        @include media.xs {
            margin-bottom: $arrow-w;

            &::after {
                bottom: calc( calc($arrow-w * -1) + calc($arrow-w / 4));
                left: 33%;
                transform: translateX(-50%) rotate(90deg);
                height: $arrow-w;
                width: 3rem;
            }
        }

        &::after {
            display: block;
            content: url("../../images/raw/side-arrow.svg");
            position: absolute;
            z-index: 2;
            pointer-events: none;
        }
    }

    // Ring
    .layer-ring {
        position: absolute;
        z-index: 2;
        top: -40rem;
        left: -30rem;
        height: 90rem;
        aspect-ratio: 1 / 1;
        max-width: 175%;
        pointer-events: none;
    }
}
