@use '../media';

$arrow-h: 13.8rem;

#decouvrir {
    position: relative;
    padding-top: calc(15dvh + var(--section-p));
    padding-top: calc(15vh + var(--section-p));
    padding-bottom: var(--section-p);
    background-color: var(--orange-100);

    header {
        margin-bottom: 2rem
    }
    @include media.sm {
        header {
            margin-bottom: $arrow-h;

            .excerpt {
                position: relative;

                &::after {
                    display: block;
                    content: url("../../images/raw/down-arrow.svg");
                    position: absolute;
                    bottom: calc( calc($arrow-h * -1) - 3rem);
                    left: 50%;
                    transform: translateX(-50%);
                    height: 13.8rem;
                    width: 5rem;
                    z-index: 2;
                    pointer-events: none;
                }
            }
        }
    }
    @include media.xs {
        header {
            .grid {
                .excerpt {
                    order: 2;
                }
            }
        }

        footer {
            .btn {
                display: block;
                width: 100%;
            }
        }
    }

    figure {
        position: relative;
        z-index: 2;
    }

    footer {
        padding-inline: 2rem;
        margin-top: 6rem;

        p {
            margin-block: 2rem;
        }
    }
}
