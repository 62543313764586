@use '../breakpoints';

.site-footer {
    padding: 3rem;
    z-index: 10;
    font-size: 0.9em;
    background-color: var(--gray-100);
    @media screen and (width < breakpoints.$mobile) {
        padding: 2rem;
    }
}
