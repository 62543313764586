@use '../media';
@use '../breakpoints';

$bottom-z: 5;

.page-info {
    position: relative;
    display: flex;
    flex-direction: column;

    .layer-ring {
        position: absolute;
        aspect-ratio: 1 / 1;
        pointer-events: none;
        user-select: none;
        top: -30%;
        left: calc(35dvw + 25%);
        left: calc(35vw + 25%);
        height: calc(50dvh + 70%);
        height: calc(50vh + 70%);
    }
    @include media.xs {
        .layer-ring {
            top: -20%;
        }
    }

    .header-info {
        display: flex;
        flex-direction: column;
        padding-top: calc(5vh + var(--section-p));
        padding-bottom: calc(5vh + var(--section-p));
        padding-left: calc(5vw + var(--section-p));
        padding-right: calc(5vw + var(--section-p));
        background-color: var(--orange-100);
        z-index: 10;

        h2 {
            font-size: 7rem;
        }

        p {
            font-size: 2rem;
        }

        @media screen and (width < breakpoints.$mobile) {
            padding-left: calc(1vw + var(--section-p));
            padding-right: calc(1vw + var(--section-p));
            padding-top: calc(2vh + var(--section-p));
            padding-bottom: calc(2vh + var(--section-p));
            h2 {
                font-size: 5rem;
            }
        }

    }

    .info-content {
        display: flex;
        flex-direction: column;
        padding-top: var(--section-p);
        padding-bottom: var(--section-p);
        padding-left: calc(5vw + var(--section-p));
        padding-right: calc(5vw + var(--section-p));
        z-index: 10;

        @media screen and (width < breakpoints.$mobile) {
            padding-left: calc(2vw + var(--section-p));
            padding-right: calc(2vw + var(--section-p));
            align-items: center;
        }

        .title-container {
            margin-bottom: 4rem;
        }

        .info-container {
            margin-bottom: 5rem;
        }

        .title-section {
            font-size: 3rem;
            text-decoration: underline;
            font-weight: 700;
        }

        .item-list-plan {

            margin-bottom: 15px;  

            a {
                font-weight: 600;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .sub-list {
            list-style: none;
        }

        .item-sub-plan {
            margin-bottom: 10px;  
        }
        
        .info-section {
            p {
                font-size: 1.8rem;
            }

            a {
                font-size: 1.8rem;
            }

            u {
                text-decoration: underline dotted;
            }

            .list-info {
                li {
                    margin-top: .5em;
                    margin-bottom: .5em;
                }
                a {
                    font-size: 1em;
                    color: var(--primary);
                }
            }
        }

        .grid-content {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
            grid-auto-rows: minmax(100px, auto);
            grid-auto-flow: column;

            @media screen and (width < breakpoints.$mobile) {
                grid-gap: unset;
                row-gap: 10px;
            }


            .grid-info {
                display: flex;
                height: 50vh;
                border-radius: 1rem;
                flex-flow: column;
                border: 2px solid;
                align-items: center;
                justify-content: center; 
                border-color: var(--orange-100);

                @media screen and (width < breakpoints.$mobile) {
                    grid-column: 12;
                    display: flex;
                    padding-left: calc(2vw + var(--section-p));
                    padding-right: calc(2vw + var(--section-p));   
                }

            }

            .info-contact {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                .icon-info {
                    color: var(--orange-400);
                    margin-bottom: 10px;
                }
            }
        }
    }
}